import React from 'react'

import { BsTwitter, BsInstagram, BsLinkedin } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'

import { images } from '../../constants'
import { AppWrap, MotionWrap } from '../../wrapper'
// import { client } from '../../client'
import './Footer.scss'

const Footer = () => {
  // const [formData, setFormData] = useState({ name: '', email: '', message: '' })
  // const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  // const [loading, setLoading] = useState(false)

  // const { name, email, message } = formData

  // const handleChangeInput = (e) => {
  //   const { name, value } = e.target
  //   setFormData({ ...formData, [name]: value })
  // }

  // const handleSubmit = () => {
  //   setLoading(true)

  //   const contact = {
  //     _type: 'contact',
  //     name: name,
  //     email: email,
  //     message: message,
  //   }

  //   client
  //     .create(contact)
  //     .then(() => {
  //       setLoading(false)
  //       setIsFormSubmitted(true)
  //     })
  //     .catch((err) => console.log(err))
  // }

  return (
    <>
      <h2 className='head-text'>Take a coffee & chat with me</h2>

      <div className='app__footer-cards'>
        <div className='app__footer-card '>
          <img src={images.email} alt='email' />
          <a href='mailto:hello@okhaimie.com' className='p-text'>
            okhai@ieee.org
          </a>
        </div>
        <div className='app__footer-card'>
          <img src={images.mobile} alt='phone' />
          <a href='tel:+1 (657) 310-2860' className='p-text'>
            +1 (657) 310-2860
          </a>
        </div>
        <a
          href='https://calendly.com/okhai-1/30min'
          target='_blank'
          rel='noreferrer'
          className='p-text'
        >
          <div className='app__footer-card'>
            <img src={images.calendar} alt='calendar' />
            Calendly
          </div>
        </a>
      </div>
      {/* {!isFormSubmitted ? (
        <div className='app__footer-form app__flex'>
          <div className='app__flex'>
            <input
              className='p-text'
              type='text'
              placeholder='Your Name'
              name='name'
              value={name}
              onChange={handleChangeInput}
            />
          </div>
          <div className='app__flex'>
            <input
              className='p-text'
              type='email'
              placeholder='Your Email'
              name='email'
              value={email}
              onChange={handleChangeInput}
            />
          </div>
          <div>
            <textarea
              className='p-text'
              placeholder='Your Message'
              value={message}
              name='message'
              onChange={handleChangeInput}
            />
          </div>
          <button type='button' className='p-text' onClick={handleSubmit}>
            {!loading ? 'Send Message' : 'Sending...'}
          </button>
        </div>
      ) : (
        <div>
          <h3 className='head-text'>Thank you for getting in touch!</h3>
        </div>
      )} */}

      <div className='app__social-footer'>
        <a
          href='https://twitter.com/sudo_okhai'
          target='_blank'
          rel='noopener noreferrer'
        >
          <div>
            <BsTwitter />
          </div>
        </a>
        <a
          href='https://www.facebook.com/okhai.omotuebe.5'
          target='_blank'
          rel='noopener noreferrer'
        >
          <div>
            <FaFacebookF />
          </div>
        </a>
        <a
          href='https://www.linkedin.com/in/okhaimie/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <div>
            <BsLinkedin />
          </div>
        </a>
        <a
          href='https://www.instagram.com/okhai.eth/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <div>
            <BsInstagram />
          </div>
        </a>
      </div>
    </>
  )
}

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg'
)
