import React from 'react'

import { BsTwitter, BsInstagram, BsLinkedin } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'

const SocialMedia = () => {
  return (
    <div className='app__social'>
      <a
        href='https://twitter.com/sudo_okhai'
        target='_blank'
        rel='noopener noreferrer'
      >
        <div>
          <BsTwitter />
        </div>
      </a>
      <a
        href='https://www.facebook.com/okhai.omotuebe.5'
        target='_blank'
        rel='noopener noreferrer'
      >
        <div>
          <FaFacebookF />
        </div>
      </a>
      <a
        href='https://www.linkedin.com/in/okhaimie/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <div>
          <BsLinkedin />
        </div>
      </a>
      <a
        href='https://www.instagram.com/okhai.eth/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <div>
          <BsInstagram />
        </div>
      </a>
    </div>
  )
}

export default SocialMedia
